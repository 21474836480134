// @codekit-prepend "../../../../node_modules/jquery/dist/jquery.min.js", "../../../../node_modules/foundation-sites/dist/js/foundation.min.js";

$(document).foundation();

gsap.registerPlugin(ScrollTrigger);
let direction = 1; // 1 = forward, -1 = backward scroll

const roll1 = roll(".rollingText", {duration: 15}),
	roll2 = roll(".rollingText02", {duration: 20}, true),
	scroll = ScrollTrigger.create({
        onUpdate(self) {
		if (self.direction !== direction) {
			direction *= -1;
			gsap.to([roll1, roll2], {timeScale: direction, overwrite: true});
		}
	}
});

// helper function that clones the targets, places them next to the original, then animates the xPercent in a loop to make it appear to roll across the screen in a seamless loop.
function roll(targets, vars, reverse) {
    const tl = gsap.timeline({
        repeat: -1,
        onReverseComplete() { 
            this.totalTime(this.rawTime() + this.duration() * 10); // otherwise when the playhead gets back to the beginning, it'd stop. So push the playhead forward 10 iterations (it could be any number)
        }
    });
    vars = vars || {};
    vars.ease || (vars.ease = "none");
    gsap.utils.toArray(targets).forEach(el => {
        let clone = el.cloneNode(true);
        el.parentNode.appendChild(clone);
        gsap.set(clone, {position: "absolute", top: el.offsetTop, left: el.offsetLeft + (reverse ? -el.offsetWidth : el.offsetWidth)});
        tl.to([el, clone], {xPercent: reverse ? 100 : -100, ...vars}, 0);
    });
    return tl;
}

gsap.set('.flag', {
    y: 75,
    scale: 0.9,
});

ScrollTrigger.batch('.flag', {
    onEnter: batch => {
      batch.forEach((flag, index) => gsap.to(flag, {opacity: 1, y: 0, scale: 1, stagger: 0.5, delay: index * 0.3}))
    },
    once: true
});

gsap.set('.staffcard', {
    y: 75,
    scale: 0.7,
});

ScrollTrigger.batch('.staffcard', {
    onEnter: batch => {
      batch.forEach((staffcard, index) => gsap.to(staffcard, {opacity: 1, y: 0, scale: 1, stagger: 0.5, delay: index * 0.3}))
    },
    once: true
});